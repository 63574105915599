import React, { useEffect, useState } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import cn from 'classnames';

import { HeroComponents } from '@blocks';
import Layout from '@components/Layout';
import { getPosts, getFeaturedPosts } from '@util/blog';
import { getClient } from '@sanity-lib/client';
import { fetchWithMenu } from '@sanity-lib/util/queries';

import defaultContent from '@content/default.json';

import styles from '@styles/pages/blog.module.css';

import BlogCarousel from '@components/blog/BlogCarousel';
import BlogListing from '@components/blog/BlogListing';
import NewsletterCallToActionCard from '@components/NewsletterCallToActionCard';
import Pagination from '@components/Pagination';
import { blogPageQuery } from '@sanity-lib/queries/pages';

const { blogPath } = defaultContent;

export async function getStaticProps({ draftMode = false }) {
  const client = getClient({ preview: draftMode });
  const content = await client.fetch(blogPageQuery);

  const latestPosts = await getPosts(content.latestPostsOnLandingPage, 1);
  const posts = await getPosts(
    content.archivePostsOnLandingPage + content.latestPostsOnLandingPage,
    1,
  );

  const featuredPosts = await getFeaturedPosts(
    content.featuredPostsInCarousel,
    1,
  );
  const pagination = posts.meta.pagination;

  const { menu } = await fetchWithMenu(client);
  return {
    props: {
      posts,
      latestPosts,
      featuredPosts,
      menu,
      pagination,
      content,
      draftMode,
    },
  };
}

export default function BlogIndex({
  posts,
  latestPosts,
  featuredPosts,
  menu,
  pagination,
  content,
}) {
  const { BlogLandingPageHero } = HeroComponents;
  const router = useRouter();
  const params = useSearchParams();
  const [page, setPage] = useState(1);

  const handlePageChange = (page) => {
    router.push(`${blogPath}/page/${page}`, {
      scroll: true,
    });
  };

  useEffect(() => {
    setPage(parseInt(params.get('page')) || 1);
  }, [params]);

  return (
    <Layout heroTheme="dark" menu={menu}>
      <BlogLandingPageHero
        heading={content.heading}
        backgroundImage="/images/blog/landing-hero-cover.png"
      />
      <main className={styles.main}>
        <div>
          <BlogListing
            className={cn(styles.container)}
            posts={latestPosts}
            hasHero
            title="Latest"
          />
        </div>

        <BlogCarousel title="Featured Blogs" items={featuredPosts} />

        <div>
          <BlogListing
            className={styles.container}
            posts={posts}
            offset={content.latestPostsOnLandingPage}
            title="Archive"
          />
        </div>
        <Pagination
          className={cn(styles.pagination, styles.container)}
          page={page}
          perPage={content.postsPerArchivePage}
          totalResults={pagination.total}
          totalPages={pagination.pages}
          onPageChange={handlePageChange}
        />
        <NewsletterCallToActionCard claim={content.ctaClaim} />
      </main>
    </Layout>
  );
}
