import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { calculatePagination } from './util';

import ArrowLeftIcon from '@assets/icons/ArrowLeft.svg';
import ArrowRightIcon from '@assets/icons/ArrowRight.svg';

import { API_PER_PAGE_SIZE } from '@constants/api';

import * as styles from './Pagination.module.css';

const Pagination = ({
  className,
  page,
  totalPages,
  totalResults,
  perPage = API_PER_PAGE_SIZE,
  onPageChange,
}) => {
  const itemsToShow = useMemo(
    () => calculatePagination(page, totalPages),
    [page, totalPages],
  );

  const { from, to } = {
    from: (page - 1) * perPage + 1,
    to: Math.min(page * perPage, totalResults),
  };

  const canGoBack = page > 1;
  const canGoForward = page < totalPages;

  return (
    <div className={cn(styles.root, className)}>
      {totalResults && (
        <div className={styles.meta}>
          Showing <strong>{from}</strong> to <strong>{to}</strong> of{' '}
          <strong>{totalResults}</strong> results
        </div>
      )}
      <nav className={styles.nav}>
        <button
          className={styles.button}
          aria-label="Go to previous page"
          disabled={!canGoBack}
          onClick={() => onPageChange(page - 1)}
        >
          <ArrowLeftIcon className={styles.buttonIcon} />
        </button>

        {itemsToShow.map((item, idx) => {
          switch (item.type) {
            case 'button':
              return (
                <button
                  key={`button-${item.page}`}
                  className={styles.button}
                  data-state={item.page === page ? 'active' : null}
                  onClick={() => onPageChange(item.page)}
                >
                  {item.page}
                </button>
              );
            case 'divider':
              return (
                <div
                  aria-hidden
                  key={`divider-${idx}`}
                  className={styles.divider}
                >
                  …
                </div>
              );
          }
        })}

        <button
          className={styles.button}
          aria-label="Go to next page"
          disabled={!canGoForward}
          onClick={() => onPageChange(page + 1)}
        >
          <ArrowRightIcon className={styles.buttonIcon} />
        </button>
      </nav>
    </div>
  );
};

Pagination.propTypes = {
  className: PropTypes.string,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  perPage: PropTypes.number,
  totalResults: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
