import React from 'react';
import PropTypes from 'prop-types';

import Carousel from '@components/Carousel';
import PageSection from '@components/PageSection';
import BlogCard from '@components/blog/BlogCard';

import styles from './BlogCarousel.module.css';

import siteInformation from '@content/default.json';

const { blogPath } = siteInformation;

const BlogCarousel = ({
  className,
  eyebrow,
  items,
  title,
  titleTextSize = 'small',
}) => {
  return (
    <PageSection
      className={className}
      title={title}
      titleTextSize={titleTextSize}
      eyebrow={eyebrow}
      theme="light"
      gradient="purple"
    >
      <Carousel
        className={styles.carousel}
        style={{
          '--carousel-item-width': 'min(420px, 90vw)',
        }}
      >
        {items.map((item, index) => (
          <BlogCard
            key={index}
            title={item.title}
            featured={item.featured}
            image={
              item.feature_image
                ? {
                    src: item.feature_image,
                    alt: item.feature_image_alt ? item.feature_image_alt : '',
                  }
                : null
            }
            href={`${blogPath}/${item.slug}`}
            publishedDate={item.published_at}
            tag={item.primary_tag?.name}
            tagSlug={item.primary_tag?.slug}
            readingTime={item.reading_time}
            textSize="xsmall"
            authors={item.authors}
          />
        ))}
      </Carousel>
    </PageSection>
  );
};

BlogCarousel.propTypes = {
  className: PropTypes.string,
  eyebrow: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
};

export default BlogCarousel;
